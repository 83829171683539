import { useState } from 'react';
import Button from './Button';
import Modal from './Modal';
import ConfirmationModal from './ConfirmationModal';

interface ImagePreviewProps {
  closeModal: (props: any) => void;
  deleteImg: (props: any) => void;
  imgUrl: string;
}

const ImagePreview = ({ closeModal, imgUrl, deleteImg }: ImagePreviewProps) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const actionButton = () => {
    return (
      <Button title="Hapus" classes="bg-red" onClick={() => deleteImg('')} />
    );
  };

  return (
    <>
      {showDeleteModal ? (
        <ConfirmationModal
          title="Hapus Foto?"
          cancelText="Batal"
          closeModal={setShowDeleteModal}
          ActionButton={actionButton}
        />
      ) : null}
      <Modal closeModal={closeModal}>
        <div className="flex flex-col p-[10px] mb-[16px] gap-[10px]">
          <div className="w-full h-[220px]">
            <img
              src={imgUrl}
              alt="delivery-img"
              className="w-full h-full object-contain"
            />
          </div>
          <Button
            title="Ubah Foto"
            classes="text-base"
            onClick={() => {
              const imgInput = document.getElementById(
                'image-upload'
              ) as HTMLInputElement;
              imgInput.click();
            }}
          />
          <Button
            title="Hapus Foto"
            classes="bg-red text-base"
            onClick={() => setShowDeleteModal(true)}
          />
        </div>
      </Modal>
    </>
  );
};

export default ImagePreview;
