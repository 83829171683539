import { createAsyncThunk } from '@reduxjs/toolkit';
import { serverAPI } from '../../utils/API';
import { enqueueSnackbar } from 'notistack';

export const checkPhoneNumber = createAsyncThunk(
  'auth/checkPhoneNumber',
  async (phoneNumberData: {}, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post('login', phoneNumberData);
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data?.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const resendOTP = createAsyncThunk(
  'auth/resendOTP',
  async (otpData: {}, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post('otp/resend', otpData);
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data?.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const driverLogin = createAsyncThunk(
  'auth/driverLogin',
  async (loginData: {}, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post('otp/submit', loginData);
      if (data.token) localStorage.setItem('DRIVER_KEY', data.token);
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data?.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);
