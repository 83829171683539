interface InputProps {
  title: string;
  placeholder: string;
  type?: string;
  register: any;
  name: string;
  classes?: string;
  errors: any;
  isDisabled?: boolean;
}

const Input = ({
  title,
  placeholder,
  type = 'text',
  register,
  name,
  classes = '',
  errors,
  isDisabled = false,
}: InputProps) => {
  return (
    <div
      className={`flex flex-row justify-between items-center mb-[28px] w-full gap-[15px] ${classes}`}
    >
      <label className="font-worksans-semibold w-[40%] text-right">
        {title}
      </label>
      <div className="w-[60%] relative">
        {name === 'phone_number' ? (
          <input
            type={type}
            placeholder={placeholder}
            {...register(name, { setValueAs: (value: number) => `${value}` })}
            className="text-sm p-[8px] border border-black-60 rounded-[6px] outline-0 bg-white"
          />
        ) : (
          <input
            type={type}
            disabled={isDisabled}
            placeholder={placeholder}
            {...register(name)}
            className={`text-sm p-[8px] border border-black-60 rounded-[6px] outline-0 bg-white ${
              isDisabled ? 'opacity-30 cursor-not-allowed' : 'opacity-100'
            }`}
          />
        )}
        {errors && errors[name] && (
          <p className="absolute bottom-[-14px] left-0 text-xs text-red">
            {errors[name].message}
          </p>
        )}
      </div>
    </div>
  );
};

export default Input;
