import { createSlice } from '@reduxjs/toolkit';
import {
  fetchDeliveryDetail,
  fetchDeliveryList,
  markAsDelivered,
} from '../actions/transaction';

interface InitialStateType {
  imageLink?: string;
  selectedSection: string;
  isLoading: boolean;
  hasNextPage: boolean;
  page: number;
  deliveryList: any;
  markAsDeliveredData?: any;
  transactionDetail: any;
  codAmount?: number;
}

const initialDeliveryData = {
  _id: '',
  amount_cod_received: 0,
  driver_app_photos: [
    {
      name: 'drop_off',
      url: '',
    },
    {
      name: 'receiver',
      url: '',
    },
    {
      name: 'pod',
      url: '',
    },
  ],
  products: [],
};

const initialState: InitialStateType = {
  imageLink: '',
  selectedSection: '',
  isLoading: false,
  hasNextPage: false,
  page: 0,
  deliveryList: [],
  transactionDetail: null,
  codAmount: 0,
  markAsDeliveredData: initialDeliveryData,
};

export const transaction = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    updateMarkAsDeliveredData: (
      { markAsDeliveredData, transactionDetail },
      { payload }
    ) => {
      const { type, data } = payload;
       //console.log(type, '====type', data, '====data');

      if (type === 'DELIVERY_PROOF') {
        const { name, url } = data;
        const idx = markAsDeliveredData.driver_app_photos.findIndex(
          (photo: any) => photo.name === name
        );
        markAsDeliveredData.driver_app_photos[idx].url = url;
      } else if (type === 'TEMP') {
        const { quantity_name, quantity, product_id } = data;
        const idx = markAsDeliveredData.products.findIndex(
          (product: any) => product.product_id === product_id
        );

        if (+quantity <= markAsDeliveredData.products[idx].order_sent) {
          markAsDeliveredData.products[idx][`${quantity_name}`] = quantity
            ? +quantity
            : quantity;

          if (
            (quantity_name === 'temp_reject' ||
              quantity_name === 'temp_shrinkage') &&
            quantity
          ) {
            const accept =
              markAsDeliveredData.products[idx][`order_sent`] -
              markAsDeliveredData.products[idx][`temp_reject`] -
              markAsDeliveredData.products[idx][`temp_shrinkage`];

            markAsDeliveredData.products[idx][`temp_accept`] =
              accept < 0 ? 0 : accept;
          }
        }

        // update cod accepted amount
        if (quantity && transactionDetail.payment_method === 'CAD') {
          markAsDeliveredData.amount_cod_received = markAsDeliveredData.products
            .map((prod: any) => prod.temp_accept * (prod.total_price/prod.order_count))
            .reduce((a: number, b: number) => a + b, 0);

            if (transactionDetail.horeca_discount_percentage)
            {
              let discountPercent = transactionDetail.horeca_discount_percentage/100;
              let discountAmount = Math.round(discountPercent * markAsDeliveredData.amount_cod_received);

              markAsDeliveredData.amount_cod_received -= discountAmount;
            } else if (transactionDetail.initial_discount_value)
            {
              let discountPercent = transactionDetail.initial_discount_value/transactionDetail.total_price;
              let discountAmount = Math.round(discountPercent * markAsDeliveredData.amount_cod_received);
              markAsDeliveredData.amount_cod_received -= discountAmount;
            }
            
        }
      } else if (type === 'COD_AMOUNT') {
        markAsDeliveredData.amount_cod_received = data ? +data : data;
      }
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSelectedSection: (state, action) => {
      state.page = 0;
      state.deliveryList = [];
      state.selectedSection = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeliveryList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDeliveryList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.hasNextPage = payload.has_next_page;
        state.deliveryList = [...state.deliveryList, ...payload.transactions];
      })
      .addCase(fetchDeliveryList.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchDeliveryDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDeliveryDetail.fulfilled, (state, { payload }) => {
        const { transaction } = payload;
        state.isLoading = false;
        state.transactionDetail = transaction;
        if (
          transaction &&
          transaction.products &&
          transaction.products.length
        ) {
          const productsData =
            transaction.status === 'Item Received'
              ? transaction.products
              : transaction.products.map((product: any) => {
                  return {
                    ...product,
                    temp_accept: product.order_sent,
                    temp_shrinkage: 0,
                    temp_reject: 0,
                  };
                });

          state.markAsDeliveredData = {
            ...state.markAsDeliveredData,
            _id: transaction._id,
            products: productsData,
            driver_app_photos:
              transaction.delivery_detail &&
              transaction.delivery_detail.driver_app_photos &&
              transaction.delivery_detail.driver_app_photos.length
                ? transaction.delivery_detail.driver_app_photos
                : initialDeliveryData.driver_app_photos,
          };

          if (
            transaction.payment_method === 'CAD' &&
            transaction.product_payment &&
            transaction.product_payment.amount_cod_received
          ) {
            state.markAsDeliveredData.amount_cod_received =
              transaction.product_payment.amount_cod_received;
          }
        }
      })
      .addCase(fetchDeliveryDetail.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(markAsDelivered.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(markAsDelivered.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.markAsDeliveredData = initialDeliveryData;
      })
      .addCase(markAsDelivered.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  setIsLoading,
  updateMarkAsDeliveredData,
  setPage,
  setSelectedSection,
} = transaction.actions;

export default transaction.reducer;
