import axios from 'axios';

console.log(process.env.REACT_APP_API);

const baseURL =
  process.env.REACT_APP_API === 'production'
    ? 'https://driver.pasarnow.com/api/'
    : 'https://staging.pasarnow.com/api/';

const serverAPI = axios.create({
  baseURL: `${baseURL}driver/app`,
});

const pasarnowAPI = axios.create({
  baseURL,
});

pasarnowAPI.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem('DRIVER_KEY');
    config.metadata = { startTime: new Date() };
    if (token) config.headers.Authorization = `Bearer ${token}`;
    config.timeout = 120000;
    return config;
  },
  (error) => Promise.reject(error)
);

pasarnowAPI.interceptors.response.use(
  (response: any) => {
    response.config.metadata.endTime = new Date();
    response.duration =
      (response.config.metadata.endTime - response.config.metadata.startTime) /
      1000;
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('DRIVER_KEY');
      window.location.assign('/login');
      setTimeout(() => {
        window.location.assign(window.location as any);
      }, 1000);
    }
    return Promise.reject(error.response.data);
  }
);

serverAPI.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem('DRIVER_KEY');
    config.metadata = { startTime: new Date() };
    if (token) config.headers.Authorization = `Bearer ${token}`;
    config.timeout = 120000;
    return config;
  },
  (error) => Promise.reject(error)
);

serverAPI.interceptors.response.use(
  (response: any) => {
    response.config.metadata.endTime = new Date();
    response.duration =
      (response.config.metadata.endTime - response.config.metadata.startTime) /
      1000;
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('DRIVER_KEY');
      window.location.assign('/login');
      setTimeout(() => {
        window.location.assign(window.location as any);
      }, 1000);
    }
    return Promise.reject(error.response.data);
  }
);

const cancelToken = axios.CancelToken;

export { serverAPI, cancelToken, baseURL, pasarnowAPI };
