import { jwtDecode } from 'jwt-decode';
import { useAppDispatch, useAppSelector } from './useRTK';
import { setUserInfo } from '../services/slices/authSlice';

export const useIsLoggedIn = () => {
  const { userInfo } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const token = localStorage.getItem('DRIVER_KEY');

  if (userInfo && userInfo.driver_id && token) return true;
  else if ((!userInfo || !userInfo.driver_id) && token) {
    var decodeToken = jwtDecode(token);
    decodeToken = JSON.parse(JSON.stringify(decodeToken));
    dispatch(setUserInfo(decodeToken));
    return true;
  }
  return false;
};
