import { Modal as ModalMUI } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import React from 'react';

interface ModalProps {
  closeModal: (props: any) => void;
  children: React.ReactNode;
}
const Modal = ({ closeModal, children }: ModalProps) => {
  const handleClose = () => closeModal(false);

  return (
    <ModalMUI open={true} onClose={handleClose}>
      <div className="w-[75%] sm:w-[25%] absolute z-100 bg-white p-[24px] rounded-lg top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <button className="bg-transparent flex ml-auto" onClick={handleClose}>
          <CancelRoundedIcon />
        </button>
        <section className="mt-[12px] max-h-[60vh] overflow-auto">
          {children}
        </section>
      </div>
    </ModalMUI>
  );
};

export default Modal;
