const getToastMessage = (obj: any) => {
  // console.log(obj, '====obj');
  const { error, payload } = obj;
  console.log(payload, '===payload');
  var result = {
    message: payload && payload.message ? payload.message : payload,
    variant: 'success',
  };
  if (error) result.variant = 'error';
  return result;
};

export default getToastMessage;
