import React from 'react';
import Button from './Button';
import Modal from './Modal';

interface ConfirmationModalProps {
  closeModal: (props: any) => void;
  ActionButton: React.FC;
  title?: string;
  subtitle?: string;
  cancelText: string;
}

const ConfirmationModal = ({
  closeModal,
  title,
  subtitle,
  cancelText,
  ActionButton,
}: ConfirmationModalProps) => {
  return (
    <Modal closeModal={closeModal}>
      <div className="flex flex-col items-center py-[10px] gap-[10px]">
        <div className="mb-[14px]">
          {title ? <h4>{title}</h4> : null}
          {subtitle ? <p className="text-center">{subtitle}</p> : null}
        </div>
        <Button
          title={cancelText}
          classes="bg-white !text-green border border-green"
          onClick={() => closeModal(false)}
        />
        <ActionButton />
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
