import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import ImageUpload from './ImageUpload';
import React, { useCallback, useEffect, useState } from 'react';
import ImagePreview from './ImagePreview';
import { useAppDispatch, useAppSelector } from '../hooks/useRTK';
import { updateMarkAsDeliveredData } from '../services/slices/transactionSlice';

const MarkAsDelivered = () => {
  const { transactionDetail, markAsDeliveredData } = useAppSelector(
    (state) => state.transaction
  );
  const dispatch = useAppDispatch();
  const [selectedImgSection, setSelectedImgSection] = useState('');
  const [imgPreview, setImgPreview] = useState('');
  const [imgLink, setImgLink] = useState('');
  const [started, setStarted] = useState(true);

  const updateImg = useCallback(
    (link: string) => {
      if (imgPreview) setImgPreview(link);
      dispatch(
        updateMarkAsDeliveredData({
          type: 'DELIVERY_PROOF',
          data: { name: selectedImgSection, url: link },
        })
      );
    },
    [dispatch, imgPreview, selectedImgSection]
  );

  const selectFile = (type: string, preview = '') => {
    if (transactionDetail.status !== 'Item Received') {
      setSelectedImgSection(type);
      if (preview) {
        setImgPreview(preview);
      } else {
        const imgInput = document.getElementById(
          'image-upload'
        ) as HTMLInputElement;
        imgInput.click();
      }
    }
  };

  const handleUpdateQuantity = (
    ev: React.ChangeEvent<HTMLInputElement>,
    productId: string
  ) => {
    const productField = ev.target.name,
      quantity = ev.target.value;
    dispatch(
      updateMarkAsDeliveredData({
        type: 'TEMP',
        data: { quantity_name: productField, quantity, product_id: productId },
      })
    );

    setStarted(false);
  };

  const handleUpdateCODAmount = (ev: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateMarkAsDeliveredData({
        type: 'COD_AMOUNT',
        data: ev.target.value,
      })
    );

    setStarted(false);
  };

  useEffect(() => {
    if (imgLink && selectedImgSection) {
      updateImg(imgLink);
      setImgLink('');
      setSelectedImgSection('');
    }
  }, [imgLink, selectedImgSection, updateImg]);

  return (
    <>
      <ImageUpload
        type="delivery-proof"
        name={selectedImgSection}
        setImage={setImgLink}
      />
      {imgPreview ? (
        <ImagePreview
          imgUrl={imgPreview}
          closeModal={setImgPreview}
          deleteImg={updateImg}
        />
      ) : null}
      <main>
        <section className="flex py-[10px] flex-row font-worksans-medium  px-[12px] gap-[8px] items-center w-full text-sm border-b border-b-black-60">
          <p>Pembayaran :</p>
          <p>{transactionDetail.payment_method}</p>
        </section>
        <section className="flex flex-col">
          <h5 className="text-lg text-black-80 py-[8px] px-[12px]">
            PRODUCT LIST
          </h5>
          <div className="flex flex-col border-t border-t-black-60">
            {markAsDeliveredData.products.map((product: any) => (
              <div
                key={product.product_id}
                className="flex flex-row p-[12px] text-center font-worksans-medium justify-between items-center w-full text-sm border-b border-black-60"
              >
                <div className="w-[65px] h-[65px] rounded-lg">
                  <img
                    src={product.images[0]}
                    alt={product.product_name}
                    className="w-full h-full object-cover rounded-lg"
                  />
                </div>
                <div className="flex flex-col w-[75%]">
                  <p className="mb-[10px]">{product.product_name}</p>
                  <div className="flex flex-row w-full justify-between">
                    <span className="flex flex-col">
                      <label className="text-[10px]">Accepted</label>
                      <input
                        type="number"
                        name="temp_accept"
                        value={
                          transactionDetail.status === 'Item Received'
                            ? product.accepted_order
                            : product.temp_accept
                        }
                        disabled={transactionDetail.status === 'Item Received'}
                        onChange={(ev) =>
                          handleUpdateQuantity(ev, product.product_id)
                        }
                        className="px-[10px] text-center outline-none py-[8px] text-sm border border-black-60 rounded-lg w-[50px]"
                      />
                    </span>
                    <span className="flex flex-col">
                      <label className="text-[10px]">Rejected</label>
                      <input
                        type="number"
                        name="temp_reject"
                        value={
                          transactionDetail.status === 'Item Received'
                            ? product.rejected_order
                            : product.temp_reject
                        }
                        disabled={transactionDetail.status === 'Item Received'}
                        onChange={(ev) =>
                          handleUpdateQuantity(ev, product.product_id)
                        }
                        className="px-[10px] text-center outline-none py-[8px] text-sm border border-black-60 rounded-lg w-[50px]"
                      />
                    </span>
                    <span className="flex flex-col">
                      <label className="text-[10px]">Shrinkage</label>
                      <input
                        type="number"
                        name="temp_shrinkage"
                        value={
                          transactionDetail.status === 'Item Received'
                            ? product.shrinkage_amount
                            : product.temp_shrinkage
                        }
                        onChange={(ev) =>
                          handleUpdateQuantity(ev, product.product_id)
                        }
                        disabled={
                          !product.is_fresh ||
                          transactionDetail.status === 'Item Received'
                        }
                        className={`px-[10px] text-center outline-none py-[8px] text-sm border border-black-60 rounded-lg w-[50px] ${
                          !product.is_fresh ||
                          transactionDetail.status === 'Item Received'
                            ? 'bg-white border-0'
                            : ''
                        }`}
                      />
                    </span>
                    <span className="flex flex-col">
                      <label className="text-[10px]">Total Sent</label>
                      <p className="px-[10px] text-center outline-none py-[8px] text-sm w-[50px]">
                        {product.order_sent}
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
        <section className="flex flex-col">
          <h5 className="text-lg text-black-80 py-[8px] px-[12px]">
            BUKTI PENGIRIMAN
          </h5>
          <div className="flex flex-col border-y border-y-black-60">
            {markAsDeliveredData.driver_app_photos.map(
              (proof: any, idx: number) => (
                <span
                  className="flex flex-row items-end px-[12px] my-[10px]"
                  key={`proof-${idx}`}
                >
                  <p className="text-sm w-[90%]">
                    Foto
                    {proof.name === 'drop_off'
                      ? ' Produk Drop Off'
                      : proof.name === 'receiver'
                      ? ' Penerima'
                      : proof.name === 'pod'
                      ? ' POD Tanda Tangan Penerima'
                      : ''}
                  </p>
                  {proof.url ? (
                    <div
                      className="w-[40px] h-[40px]"
                      onClick={() => selectFile(proof.name, proof.url)}
                    >
                      <img
                        src={proof.url}
                        alt={`${proof.name}-img`}
                        className="w-full h-full object-contain"
                      />
                    </div>
                  ) : (
                    <button
                      type="button"
                      onClick={() => selectFile(proof.name)}
                    >
                      <PhotoCameraOutlinedIcon />
                    </button>
                  )}
                </span>
              )
            )}
          </div>
        </section>
        {transactionDetail.payment_method === 'CAD' ? (
          <section className="flex flex-row justify-between border-b border-b-black-60 items-center py-[8px] px-[12px] text-sm">
            <label>Jumlah Terima:</label>
            <span className="w-[70%]">
              Rp
              <input
                type="number"
                value={
                  transactionDetail.status === 'Item Received'
                    ? transactionDetail.product_payment.amount_cod_received
                    : started ? transactionDetail.total_payment : markAsDeliveredData.amount_cod_received
                }
                onChange={handleUpdateCODAmount}
                disabled={transactionDetail.status === 'Item Received'}
                className="p-[8px] outline-none w-[85%] ml-[5px] border border-black-60 rounded-lg"
              />
            </span>
          </section>
        ) : null}
      </main>
    </>
  );
};

export default MarkAsDelivered;
