import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { useEffect, useState } from 'react';
import Button from '../components/Button';
import MarkAsDelivered from '../components/MarkAsDelivered';
import { useAppDispatch, useAppSelector } from '../hooks/useRTK';
import {
  fetchDeliveryDetail,
  markAsDelivered,
  markAsDeliveredB2C,
} from '../services/actions/transaction';
import Loading from '../components/Loading';
import ConfirmationModal from '../components/ConfirmationModal';
import { enqueueSnackbar } from 'notistack';
import getToastMessage from '../utils/toastMessage';
import Modal from '../components/Modal';
import { setSelectedSection } from '../services/slices/transactionSlice';

const TransactionDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [section, setSection] = useState('detail');
  const [confirmModal, setConfirmModal] = useState<any>(null);
  const [invalidQtyModal, setInvalidQtyModal] = useState<any>('');
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { transactionDetail, isLoading, markAsDeliveredData } = useAppSelector(
    (state) => state.transaction
  );
  const addressFields = [
    {
      placeholder: 'Nama Outlet',
      field: 'outlet_name',
    },
    {
      placeholder: 'Nama',
      field: 'name',
    },
    {
      placeholder: 'Nomor HP',
      field: 'phone_number',
    },
    {
      placeholder: 'Alamat',
      field: 'address',
    },
    {
      placeholder: 'Kecamatan',
      field: 'suburb',
    },
    {
      placeholder: 'Kota',
      field: 'city',
    },
  ];

  const confirmMarkAsDelivered = async (rejectAll = false) => {
    const valid = validateProducts();
    const allUrlsFilled = markAsDeliveredData.driver_app_photos.every(
      (photo: { name: string; url: string }) => photo.url !== ''
    );

    if (!rejectAll) {
      if (valid !== true) {
        return setInvalidQtyModal(valid);
      /*} else if (
        transactionDetail.payment_method === 'CAD' &&
        !markAsDeliveredData.amount_cod_received
      ) {
        return enqueueSnackbar(`Jumlah Terima harus diisi`, {
          variant: 'warning',
        });*/
      } /* else if (
        transactionDetail.payment_method != 'CAD'
      ) {
        return enqueueSnackbar(`Jumlah Terima harus diisi`, {
          variant: 'warning',
        });
      } */ else if (!allUrlsFilled && !rejectAll) {
        return enqueueSnackbar(`Semua Bukti Pengiriman harus diisi`, {
          variant: 'warning',
        });
      }
    }

    const payload = JSON.parse(JSON.stringify(markAsDeliveredData));
    if (rejectAll) payload[`is_rejected_all`] = true;

    const done = await dispatch(
      transactionDetail.is_horeca
        ? markAsDelivered(payload)
        : markAsDeliveredB2C(payload)
    );
    if (done) {
      const { variant } = getToastMessage(done);
      if (variant === 'success') {
        enqueueSnackbar(`Pesanan selesai!`, { variant });
        dispatch(setSelectedSection(''));
        navigate('/?type=sent');
      }
    }
  };

  const validateProducts = () => {
    const products = markAsDeliveredData.products;
    for (const {
      temp_accept,
      temp_reject,
      temp_shrinkage,
      order_sent,
      is_fresh,
      product_name,
    } of products) {
      if (
        temp_accept + temp_reject + (is_fresh ? temp_shrinkage : 0) !==
        order_sent
      ) {
        return product_name;
      }
    }
    return true;
  };

  const showConfirmModal = ({ type = '' }) => {
    if (type === 'sent') {
      setConfirmModal({
        actionButton: () => {
          return (
            <Button title="Sesuai" onClick={() => confirmMarkAsDelivered()} />
          );
        },
        subtitle: 'Pastikan data yang diinput sudah sesuai',
        cancelText: 'Tutup',
      });
    } else if (type === 'reject') {
      setConfirmModal({
        actionButton: () => {
          return (
            <Button
              title="Yakin"
              onClick={() => confirmMarkAsDelivered(true)}
            />
          );
        },
        subtitle: 'Pesanan di Reject Semua?',
        cancelText: 'Tutup',
      });
    }
  };

  const InvalidModal = ({ productName }: any) => {
    return (
      <Modal closeModal={setInvalidQtyModal}>
        <p className="text-center">
          Mohon cek kembali jumlah Accepted, Rejected, Shrinkage untuk produk{' '}
          <span className="font-worksans-semibold">{productName}</span>
        </p>
      </Modal>
    );
  };

  useEffect(() => {
    const paramsSection = searchParams.get('section');
    paramsSection ? setSection(paramsSection) : setSection('detail');
  }, [searchParams]);

  useEffect(() => {
    if (id) dispatch(fetchDeliveryDetail({ id }));
  }, [dispatch, id]);

  return (
    <>
      {isLoading ? <Loading /> : null}
      {confirmModal ? (
        <ConfirmationModal
          closeModal={setConfirmModal}
          subtitle={confirmModal.subtitle}
          cancelText={confirmModal.cancelText}
          ActionButton={confirmModal.actionButton}
        />
      ) : null}
      {invalidQtyModal ? <InvalidModal productName={invalidQtyModal} /> : null}
      {transactionDetail ? (
        <>
          <header className="flex flex-row items-center border-b border-b-black-60 gap-[24px] p-[16px]">
            <button type="button" onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </button>
            <h3 className="text-[20px]">{transactionDetail.transaction_id}</h3>
          </header>
          {section === 'mark_as_delivered' ||
          transactionDetail.status === 'Item Received' ? (
            <MarkAsDelivered />
          ) : (
            <main className="px-[12px]">
              <section className="flex flex-col mt-[12px]">
                <h5 className="text-lg text-black-80">INFORMASI PENERIMA</h5>
                <div className="flex flex-col gap-[10px] py-[14px] border-b border-b-black-60">
                  {addressFields.map((field) => (
                    <span
                      key={field.field}
                      className="flex flex-row font-worksans-medium justify-between items-center w-full text-sm"
                    >
                      <p className="w-[30%] text-right">
                        {field.placeholder} :
                      </p>
                      <p
                        className={`w-[68%] ${
                          field.field === 'phone_number'
                            ? 'text-blue cursor-pointer'
                            : ''
                        }`}
                        onClick={() => {
                          if (field.field === 'phone_number') {
                            window.open(
                              `tel:${transactionDetail[`${field.field}`]}`
                            );
                          }
                        }}
                      >
                        {field.field === 'city' || field.field === 'suburb'
                          ? transactionDetail.address[`${field.field}`].name
                          : transactionDetail.address[`${field.field}`]}
                      </p>
                    </span>
                  ))}
                  <button
                    type="button"
                    className="flex flex-row items-center ml-auto text-blue text-sm font-worksans-medium"
                    onClick={() =>
                      window.open(
                        `https://www.google.com/maps/search/?api=1&query=${transactionDetail.address.latitude},${transactionDetail.address.longitude}`
                      )
                    }
                  >
                    Buka Peta
                    <MapOutlinedIcon
                      sx={{ color: '#2F80ED', marginLeft: '5px' }}
                    />
                  </button>
                </div>
              </section>
              <section className="flex flex-col mt-[12px]">
                <h5 className="text-lg text-black-80">PRODUCT LIST</h5>
                <div className="flex flex-col py-[14px]">
                  <div className="flex flex-row text-center justify-between items-center w-full border-b border-black-60 text-sm font-worksans-medium">
                    <p className="w-[65px]">Gambar</p>
                    <p className="w-[55%]">Nama Produk</p>
                    <p className="w-[15%]">Jumlah</p>
                  </div>
                  <div>
                    {transactionDetail.products.map((product: any) => (
                      <span
                        key={product.product_id}
                        className="flex flex-row p-[12px] text-center font-worksans-medium justify-between items-center w-full text-sm border-b border-black-60"
                      >
                        <div className="w-[65px] h-[65px] rounded-lg">
                          <img
                            src={product.images[0]}
                            alt={product.product_name}
                            className="w-full h-full object-cover rounded-lg"
                          />
                        </div>
                        <p className="w-[55%]">{product.product_name}</p>
                        <p className="w-[15%]">{product.order_sent}</p>
                      </span>
                    ))}
                  </div>
                </div>
              </section>
            </main>
          )}
          {transactionDetail.status === 'Supplier Confirmed' ? (
            <footer>
              {section === 'detail' ? (
                <button
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    setSearchParams({ section: 'mark_as_delivered' });
                  }}
                  className="flex flex-row items-center rounded-lg gap-[8px] justify-center mx-auto my-[14px] w-[80%] font-worksans-semibold py-[10px] bg-yellow"
                >
                  <LocalShippingIcon />
                  Mark as Delivered
                </button>
              ) : section === 'mark_as_delivered' ? (
                <div className="flex flex-col gap-[18px] w-[80%] mx-auto my-[14px]">
                  <Button
                    title="Selesai"
                    classes="bg-yellow !text-black-100"
                    onClick={() => showConfirmModal({ type: 'sent' })}
                  />
                  <Button
                    title="Reject Semua"
                    onClick={() => showConfirmModal({ type: 'reject' })}
                  />
                </div>
              ) : null}
            </footer>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default TransactionDetailPage;
