import { createSlice } from '@reduxjs/toolkit';
import { checkPhoneNumber, driverLogin, resendOTP } from '../actions/auth';

interface InitialStateType {
  userInfo: {
    name?: string;
    phone_number: string;
    driver_id: string;
  };
  isLoading: boolean;
}

const initialState: InitialStateType = {
  userInfo: {
    phone_number: '',
    driver_id: '',
  },
  isLoading: false,
};

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserInfo: (state, { payload }) => {
      state.userInfo = {
        phone_number: payload.phone_number,
        driver_id: payload._id,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkPhoneNumber.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(checkPhoneNumber.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(checkPhoneNumber.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(resendOTP.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resendOTP.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(resendOTP.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(driverLogin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(driverLogin.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (payload.driver) state.userInfo = payload.driver;
      })
      .addCase(driverLogin.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

// Action creators are generated for each case reducer function
export const { setUserInfo } = auth.actions;

export default auth.reducer;
