import { createAsyncThunk } from '@reduxjs/toolkit';
import { serverAPI } from '../../utils/API';
import { enqueueSnackbar } from 'notistack';

export const fetchDeliveryList = createAsyncThunk(
  'transaction/fetchDeliveryList',
  async (params: any, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get(`transaction/${params.type}`, {
        params: {
          page: params.page,
        },
      });
      return data;
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchDeliveryDetail = createAsyncThunk(
  'transaction/fetchDeliveryDetail',
  async (params: any, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get(`transaction/${params.id}`);
      return data;
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const markAsDelivered = createAsyncThunk(
  'transaction/markAsDelivered',
  async (markAsDelivredData: {}, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post(
        `transaction/action/delivered/horeca`,
        markAsDelivredData
      );
      return data;
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const markAsDeliveredB2C = createAsyncThunk(
  'transaction/markAsDelivered',
  async (markAsDelivredData: {}, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post(
        `transaction/action/delivered`,
        markAsDelivredData
      );
      return data;
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);
