import moment from 'moment';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { useNavigate } from 'react-router-dom';

const TransactionCard = ({ detail }: any) => {
  const navigate = useNavigate();

  if (detail) {
    return (
      <div
        className="flex flex-row cursor-pointer items-center justify-between px-[24px] py-[14px] border-b border-b-black-60"
        onClick={() => navigate(`/transaction/${detail._id}`)}
      >
        <div className="w-[65px] h-[65px] rounded-lg">
          <img
            src={detail.products[0].images[0]}
            alt={detail.products[0].product_name}
            className="w-full h-full object-cover rounded-lg"
          />
        </div>
        <div className="flex flex-col gap-[4px]">
          <p className="font-worksans-semibold">{detail.transaction_id}</p>
          <p className="font-worksans-semibold">{detail.address.outlet_name}</p>
          <p className="font-worksans-semibold text-sm text-grey-40">
            {moment(detail.item_delivery.expected_delivery_date).format(
              'D MMM YYYY'
            )}{' '}
            {detail.item_delivery.expected_delivery_time}
          </p>
        </div>
        <ArrowForwardIosOutlinedIcon sx={{ fontSize: '20px' }} />
      </div>
    );
  }

  return null;
};

export default TransactionCard;
