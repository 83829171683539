import React, { useEffect, useRef } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TransactionCard from '../components/TransactionCard';
import { useAppDispatch, useAppSelector } from '../hooks/useRTK';
import { fetchDeliveryList } from '../services/actions/transaction';
import { useSearchParams } from 'react-router-dom';
import Loading from '../components/Loading';
import {
  setPage,
  setSelectedSection,
} from '../services/slices/transactionSlice';

const TransactionsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { deliveryList, isLoading, hasNextPage, page, selectedSection } =
    useAppSelector((state) => state.transaction);
  const observerTarget = useRef(null);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSearchParams({ type: newValue });
  };

  useEffect(() => {
    const transactionType = searchParams.get('type');
    dispatch(setSelectedSection(transactionType ? transactionType : 'unsent'));
  }, [dispatch, searchParams]);

  useEffect(() => {
    if (selectedSection) {
      dispatch(fetchDeliveryList({ type: selectedSection }));
    }
  }, [dispatch, selectedSection]);

  // infinite scroll
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isLoading) {
          dispatch(setPage(page + 1));
          dispatch(
            fetchDeliveryList({ type: selectedSection, page: page + 1 })
          );
        }
      },
      { threshold: 0.6 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [dispatch, hasNextPage, isLoading, observerTarget, page, selectedSection]);

  return (
    <>
      {isLoading ? <Loading /> : null}
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={selectedSection ? selectedSection : 'unsent'}
          onChange={handleChange}
          variant="fullWidth"
          sx={{
            '& button': {
              color: '#487E5F',
              fontSize: '16px',
              fontFamily: 'WorkSansSemiBold',
              textTransform: 'capitalize',
            },
            '& button.Mui-selected': {
              color: '#487E5F',
              borderBottom: '2px solid #F8C40D',
            },
          }}
          TabIndicatorProps={{
            style: { backgroundColor: '#F8C40D' },
          }}
        >
          <Tab value="unsent" label="Perlu Diantar" />
          <Tab value="sent" label="Selesai" />
        </Tabs>
      </Box>
      {deliveryList && deliveryList.length ? (
        <section id="delivery-list">
          {deliveryList.map((list: any) => (
            <TransactionCard detail={list} key={list.transaction_id} />
          ))}
          <div ref={observerTarget}></div>
        </section>
      ) : (
        <span className="flex justify-center items-center text-black-70 h-[40vh] w-[60%] text-center m-auto">
          Belum ada pengiriman yang{' '}
          {selectedSection === 'sent' ? 'Selesai' : 'Perlu Diantar'}
        </span>
      )}
    </>
  );
};

export default TransactionsPage;
