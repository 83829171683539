export const formatTimeFromSeconds = (seconds: number) => {
  if (typeof seconds !== 'number' || isNaN(seconds) || seconds <= 0) {
    return false;
  }
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds =
    remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

  return `${formattedMinutes}:${formattedSeconds}`;
};
