import { Route, Routes } from 'react-router-dom';
import LoginPage from './pages/Login';
import TransactionsPage from './pages/Transactions';
import TransactionDetailPage from './pages/TransactionDetail';
import { Provider } from 'react-redux';
import { store } from './services/store';
import PrivateRoutes from './components/PrivateRoutes';

function App() {
  return (
    <Provider store={store}>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<TransactionsPage />} />
          <Route path="/transaction/:id" element={<TransactionDetailPage />} />
        </Route>
      </Routes>
    </Provider>
  );
}

export default App;
