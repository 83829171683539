import { useState, useEffect } from 'react';
import { formatTimeFromSeconds } from '../utils/date';

interface CountdownProps {
  initialTime: number;
  resendFn: (props?: any) => void;
}

const Countdown = ({ initialTime, resendFn }: CountdownProps) => {
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime((prevTime) => (prevTime - 1 < 0 ? 0 : prevTime - 1));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <span
      className={`text-yellow cursor-pointer font-worksans-bold ${
        !time && 'underline'
      }`}
      onClick={() => {
        if (!time) {
          setTime(initialTime ? initialTime : 60);
          resendFn();
        }
      }}
    >
      {!time ? 'Kirim OTP' : `Coba lagi dalam ${formatTimeFromSeconds(time)}`}
    </span>
  );
};

export default Countdown;
