import { useForm } from 'react-hook-form';
import { loginUser } from '../utils/validationForm';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '../components/Input';
import React, { useEffect, useState } from 'react';
import Button from '../components/Button';
import {
  checkPhoneNumber,
  driverLogin,
  resendOTP,
} from '../services/actions/auth';
import { useAppDispatch, useAppSelector } from '../hooks/useRTK';
import getToastMessage from '../utils/toastMessage';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useIsLoggedIn } from '../hooks/useAuth';
import Countdown from '../components/Countdown';
import DriverLogo from '../assets/imgs/driver-logo.png';

interface LoginInput {
  phone_number: string;
  code?: string;
}

const LoginPage: React.FC = () => {
  const {
    register,
    handleSubmit,
    setError,
    reset,
    getValues,
    formState: { errors },
  } = useForm<LoginInput>({
    resolver: yupResolver(loginUser),
  });
  const isLoggedIn = useIsLoggedIn();
  const { userInfo, isLoading } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showOTP, setShowOTP] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number>(0);

  const onSubmit = (data: LoginInput) => {
    // console.log(data, '====');
    const { code, phone_number } = data;

    if (!code && phone_number && !showOTP) {
      return checkPhone(data);
    } else if (!code && showOTP) {
      return setError('code', { type: 'required', message: 'OTP harus diisi' });
    } else if (code && showOTP && phone_number) {
      return submitLogin(data);
    }
  };

  const checkPhone = async (data: LoginInput) => {
    const done = await dispatch(checkPhoneNumber(data));
    if (done) {
      const { variant } = getToastMessage(done);
      if (variant === 'success') {
        setCountdown(60);
        setShowOTP(true);
      }
    }
  };

  const submitLogin = async (data: LoginInput) => {
    const done = await dispatch(driverLogin(data));
    if (done) {
      const { variant } = getToastMessage(done);
      if (variant === 'success') {
        reset();
        navigate('/');
      }
    }
  };

  const driverResendOTP = async () => {
    const done = await dispatch(resendOTP(getValues()));
    if (done) {
      const { variant, message } = getToastMessage(done);
      if (variant === 'success') {
        enqueueSnackbar(message, { variant });
        setCountdown(60);
      }
    }
  };

  useEffect(() => {
    if ((userInfo && userInfo.driver_id) || isLoggedIn) navigate('/');
  }, [userInfo, navigate, isLoggedIn]);

  return (
    <main className="flex flex-col items-center justify-center h-[100vh]">
      <div className="w-[90%] h-[25vh] mb-12">
        <img
          src={DriverLogo}
          alt="driver-logo"
          className="w-full h-full object-contain object-center"
        />
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col items-center justify-center"
      >
        <Input
          title="Nomor HP:"
          name="phone_number"
          placeholder="08123456789"
          register={register}
          errors={errors}
          type="number"
        />
        {showOTP ? (
          <div className="flex flex-col">
            <Input
              title="OTP:"
              name="code"
              placeholder="123456"
              register={register}
              errors={errors}
            />
            <p className="mb-[16px] text-sm text-green text-center px-[32px]">
              Belum menerima OTP?{' '}
              <Countdown initialTime={countdown} resendFn={driverResendOTP} />
            </p>
          </div>
        ) : null}
        <Button title="Lanjut" type="submit" classes="!w-[50%] mt-[16px]" />
      </form>
    </main>
  );
};

export default LoginPage;
