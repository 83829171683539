import React, { ComponentProps } from 'react';

interface ButtonProps extends ComponentProps<'button'> {
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>[`type`];
  title: string;
  classes?: string;
  disabled?: boolean;
}

const Button = ({ type, classes, title, disabled, ...props }: ButtonProps) => {
  return (
    <button
      type={type || 'button'}
      className={`bg-green py-[10px] font-worksans-semibold text-white rounded-lg text-center w-full ${
        disabled && 'opacity-50'
      } ${classes}`}
      {...props}
    >
      {title}
    </button>
  );
};

export default Button;
