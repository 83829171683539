import { useEffect, useState } from 'react';
import { pasarnowAPI } from '../utils/API';
import { setIsLoading } from '../services/slices/transactionSlice';
import Loading from './Loading';
import { enqueueSnackbar } from 'notistack';
import { useAppDispatch, useAppSelector } from '../hooks/useRTK';

interface ImageUploadProps {
  type: string;
  name: string;
  fileToUpload?: any;
  setImage: (props: any) => void;
}

const ImageUpload = ({
  type,
  name,
  setImage,
  fileToUpload,
}: ImageUploadProps) => {
  const [file, setFile] = useState<File | null>();
  const isLoading = useAppSelector((state) => state.transaction.isLoading);
  const dispatch = useAppDispatch();

  const convToBase64 = (img: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const convertBase64ToFile = (base64: any, name: string) => {
    var arr = base64.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    var convertedFile = new File(
      [u8arr],
      getFileName(name, mime.split('/')[1]),
      {
        type: mime,
      }
    );
    return convertedFile;
  };

  const getFileName = (name = 'img', extension = 'jpg') => {
    var d = new Date().getDate(),
      m = new Date().getMonth(),
      y = new Date().getFullYear(),
      t = new Date().getTime();
    return `${name}-${m + d + y + t}.${extension}`.toLowerCase();
  };

  const uploadImage = (formData: any) => {
    dispatch(setIsLoading(true));

    pasarnowAPI
      .post('imageUpload', formData)
      .then((res) => {
        if (res) {
          const { data } = res;
          if (data && data.path) setImage(data.path);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data.message) {
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        } else {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      })
      .finally(() => {
        setFile(null);
        dispatch(setIsLoading(false));
      });
  };

  const compressImage = async (file : any, { quality = 1, type = file.type }) =>{
    // Get as image data
    const imageBitmap = await createImageBitmap(file);

    // Draw to canvas
        const canvas = document.createElement('canvas');

        let imgWidth = imageBitmap.width;
        let imgHeight = imageBitmap.height;

        if (imgWidth > 1024 || imgHeight > 1024)
        {
          let resizeRatio = 0.0;

          if (imgWidth > imgHeight)
          {
            resizeRatio = imgWidth/1024;
          }
          else
          {
            resizeRatio = imgHeight/1024;
          }

          imgWidth = imgWidth/resizeRatio;
          imgHeight = imgHeight/resizeRatio;
        }



        canvas.width = imgWidth;
        canvas.height = imgHeight;

        const ctx = canvas.getContext('2d');
        if (ctx)
        {
          ctx.drawImage(imageBitmap, 0, 0, imgWidth, imgHeight);
        }
        
        // Turn into Blob
        return await new Promise((resolve) =>
            canvas.toBlob(resolve, type, quality)
        );
  }

  useEffect(() => {
    
    async function uploadCompressedImage(){
       if (file) {

        const comImage : any = await compressImage(file, {quality : 1.0, type : 'image/jpeg'});

        if (!comImage) return;

        //const compressFile = new File([comImage], file.name);

        //console.log(compressFile.name);

        convToBase64(comImage).then((res) => {

          console.log(name);
          const image = convertBase64ToFile(res, name);
          const extension = image.name.split('.');
          const formattedFilename = getFileName(
            name,
            extension[extension.length - 1]
          );

          const formdata = new FormData();
          formdata.append('file', image, formattedFilename);
          formdata.append('imagename', formattedFilename);
          formdata.append('image_type', type);

          uploadImage(formdata);
        });
      }

    }

    uploadCompressedImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    

    /*
    if (file) {

      convToBase64(file).then((res) => {
        const image = convertBase64ToFile(res, name);
        const extension = image.name.split('.');
        const formattedFilename = getFileName(
          name,
          extension[extension.length - 1]
        );

        const formdata = new FormData();
        formdata.append('file', image, formattedFilename);
        formdata.append('imagename', formattedFilename);
        formdata.append('image_type', type);

        uploadImage(formdata);
      });
    }
    */


  }, [file]);

  useEffect(() => {
    if (fileToUpload && typeof fileToUpload !== 'string') setFile(fileToUpload);
  }, [fileToUpload]);

  const onFileChange = (event: { target: HTMLInputElement }) => {
    const files = event.target.files;
    if (files && files?.length) {
      setFile(files[0]);
      event.target.value = '';
    }
  };

  return (
    <>
      {isLoading ? <Loading /> : null}
      <input
        type="file"
        id="image-upload"
        accept="image/*"
        onChange={onFileChange}
        className="hidden"
      />
    </>
  );
};

export default ImageUpload;
